import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance </AnchorLink>
      <AnchorLink mdxType="AnchorLink">Interaction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Upload state</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Removing files</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`File Uploader`}</em>{` allows the user to transfer a file or submit content of their own.`}</p>
    <ul>
      <li parentName="ul">{`A File Uploader is commonly found in forms, but they can also live as stand alone elements.`}</li>
      <li parentName="ul"><strong parentName="li">{`Add files`}</strong>{` is the default text that appears with the File Uploader.`}</li>
      <li parentName="ul">{`A File Uploader should always be accompanied by `}<strong parentName="li">{`Submit`}</strong>{` or `}<strong parentName="li">{`Upload`}</strong>{`, which is to be styled as a `}<a parentName="li" {...{
          "href": "/components/button"
        }}>{`Primary Button`}</a>{`.`}</li>
      <li parentName="ul">{`Use an ellipsis (…) if the filename extends beyond the width of its parent element.`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAB50lEQVQ4y52TS0tbQRTHJxO1FOJrp0g10S+gFOkiIiq4aHAXCASqwZhF9FrwGwiNQhfd9QMI7lxos+ouz01W4gMRhBpzn0q87yRu1DueO7m50BaVOPDjP+fMzJ9z58xF6JkRCoVQNBpFkUiExi1td3gc/hptmY2N+lv6PhDwDwb8w0OTHyd8yeRqRzgc7mq7wsVYnOqXWMK3uBQbAx1JMht9X9eZvjWG6U+n015CiEeWZVyr1VxM06Sq6zrWNI16OEoca4KBHuDdW+5KUZTm5OTSaGrZHDz9o8yfldU5qapPm4Ye1HR9yjCM4L9Ada5ChdPAgFuhpinYDsBg4VoSz4HDRqNxBBw7+h/1er01PwTjCzCKOIYYqapKuwoL46IofmdZ7hsvCCnBged5F5ZlU7AnJUlS6vKK2y5X+C1Bqv6QVfOT7VGVNY9tiB3DWdj8m+O4Azj8qwXELpVKBXJC+uZa3K/dlnfvlKudx7q0Ryx9jnbhvvm1CDrYfhc+k0kUIkE0T6bQDKF3iGahwVAVnUOlqFQq4UwmgwuFAi4Wizifz1Oy2SzO5XJ49ee5197bmbgP9DJWtXvNekAJQvCKlbTzoF4Eb4ka2voaE5sPzd8pbnnh8BDwAcWtYcBHF+LWGx7cMnkx/wTaY3oatmCMVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "file uploader example",
            "title": "file uploader example",
            "src": "/static/12d3ad8f31dcaddcb13eae2ce0a5da44/fb070/file-uploader-usage-1.png",
            "srcSet": ["/static/12d3ad8f31dcaddcb13eae2ce0a5da44/d6747/file-uploader-usage-1.png 288w", "/static/12d3ad8f31dcaddcb13eae2ce0a5da44/09548/file-uploader-usage-1.png 576w", "/static/12d3ad8f31dcaddcb13eae2ce0a5da44/fb070/file-uploader-usage-1.png 1152w", "/static/12d3ad8f31dcaddcb13eae2ce0a5da44/c3e47/file-uploader-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <ol>
      <li parentName="ol">{`The user may select 1 or more files to upload at a time. By default, any file type is accepted, but you can add parameters to validate a specific file type.`}</li>
      <li parentName="ol">{`The action of clicking `}<strong parentName="li">{`Add files`}</strong>{` will trigger a browser-specific upload window.`}</li>
      <li parentName="ol">{`Once the user chooses files to upload, the browser-specific upload window closes and the files will appear below the `}<strong parentName="li">{`Add files`}</strong>{` button.`}</li>
      <li parentName="ol">{`User clicks `}<strong parentName="li">{`Submit`}</strong>{` or `}<strong parentName="li">{`Upload`}</strong>{` to submit their data.`}</li>
      <li parentName="ol">{`Any errors that may occur with the file should appear as an inline error
`}<a parentName="li" {...{
          "href": "/components/notification"
        }}>{`Notification`}</a>{`.`}</li>
    </ol>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.98913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAACmUlEQVQ4y51TzU8TQRwdbIlyMKgniIZCajAeVDzIxcSYYIJNuFhoTZqIGptyNVga5cpd43/QiybaiyYUauguHiwmYBA0QqLRfmxb2I+Z7X5U+gXjb6cWNUFj+SUv7zdvZ968mcyioaEhZFUgEEB9fX2ov78fDQwMIJ/PhzweD/J6vex7g/+rXC7XP783Zdbt7GXc4+w91O081enocTrOnjt/ZHx83D48PHwQzFqaMr19/WqD2256B3tueFyOO6OeY2NjY+1+v//o1NRUK6UUCYLQYhjGX6Hr+k/HE7TOndSOjtN2hGgb2mdJkoTQ00SJDZ4ktg4/X6yemV2tnl75qp9UVdVhQdO0Lti9y+IGrHFDA3aYptkhimLdVSvgAxYXTf3SRk5YywrpFV3XPsCkj3CUP7CHtgqGXwghEbgWGzMkmLBLL6iqUxKlyc1NMYQVHCIY15mQELZ6gCzLIUVRQpCc6TBnAvrJglq4BYYsGFJNkzVGuXQxJ4pLqXQ6IWSFhXQmsyBks4xBY/iWTC4kUymmWz3gTUYQlhWMw7sJi/F5ltB8GXVWPq3fL2SEIEmlGZRkKoiBNSEbpIYZpLrxC5oepAXt3jZRH1QUPLqbsDjHM2fzxfQI1U1aIyotSTKtyAqtYsIYFlO6A0uqNUq3oalU6e8FhnmgVmZYWlpm/J173UHN4giYXCvLihuM3DVMGJcl2W3mNxiKG5tuNZ1xQ7pBMLlMaztXYLMLEKZl9/2U371v+s1Nz8z4pmeij2djsYeAu4D6kbfeLtaP/opDOwq2QQKbkc3ZjFy+3gNbgHQ2HfTU2rrdms9x3KP43NxnnufX5nn+Gc9xtv3+ECgSieypwybNGUEaFA6HWR+NRplxPB5neiwWY/oPflcoqBA77RAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "file uploader error",
            "title": "file uploader error",
            "src": "/static/6b68b27597c343d448f4ead8cc16edab/fb070/file-uploader-usage-2.png",
            "srcSet": ["/static/6b68b27597c343d448f4ead8cc16edab/d6747/file-uploader-usage-2.png 288w", "/static/6b68b27597c343d448f4ead8cc16edab/09548/file-uploader-usage-2.png 576w", "/static/6b68b27597c343d448f4ead8cc16edab/fb070/file-uploader-usage-2.png 1152w", "/static/6b68b27597c343d448f4ead8cc16edab/c3e47/file-uploader-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "upload-state"
    }}>{`Upload state`}</h2>
    <p>{`Developers using File Uploader will be able to use JavaScript to inject a Loading component when selected files are actually being uploaded.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACCUlEQVQ4y52TTWsTQRyHJ5PQg1rNSaHF2Ebw5kWiB1MCihXMSQkEAsbYpNXS7cEv4MXQg3jyEwievPiSk6e8nXIRYwWxSI27m53duskmO7O70WLYcWbSBgRzSAcefjOzuw//2ZkBYEJLJpMgk8mAdDotxoc5bQsc8E+bShZdPHeYx6KLC3PRhcjc5dilEw8erodSqdTM1BVmcwWRd3Ors9l7ufPZ+2uRdelReFPaCEvSZrhUKgUppYFutwsdxxlDCIGu60DTIhCZRDi0UdIDNQ0yTjJmjvKvvmkjKdhuYZGfW2R+e9da/vKjd0037QTBdrxv20sY4/gkHILjrMKEbpIz3CEqtfsW5AMmuGPo6Kth6B88z216nveJ0fwfriueNwcD76PVd3aMDr7NHXqHQNDr9cSu2hjHEEJPFVV9omlakdNut8fJURSliJBWRLpe3G2pW7Kibcla5xnquDHuUPdIgAtFhWwJy0z4XlXVN+zjtxzWHydHlmU21t7tGeg1Mb+//N2XX/wiP18N950Ed+wPsHAB0zSn34Vb9ArjKrhJl8B1elrM3WAbzJYk+pZlgUajAcvlMqzX64JarSayUqnAarUK157vhPi7ocKfC6ck35rd8IdglVJY8FfErSj4QcDOkxDy5LAzNpGLj4ej65T3Q0wyzzgL8n6EcVw8yPtHOHArdML8SPYXkP15bxD+S4MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "File uploading state",
            "title": "File uploading state",
            "src": "/static/b67e44474011bf79a8a007f5ab4d565a/fb070/file-uploader-usage-3.png",
            "srcSet": ["/static/b67e44474011bf79a8a007f5ab4d565a/d6747/file-uploader-usage-3.png 288w", "/static/b67e44474011bf79a8a007f5ab4d565a/09548/file-uploader-usage-3.png 576w", "/static/b67e44474011bf79a8a007f5ab4d565a/fb070/file-uploader-usage-3.png 1152w", "/static/b67e44474011bf79a8a007f5ab4d565a/c3e47/file-uploader-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "removing-files"
    }}>{`Removing files`}</h2>
    <p>{`Developers will use JavaScript to inject a “close” button on each file that is selected to be uploaded. It’s up to the developer to code the logic for removing these files individually. However, keep in mind that this kind of editing isn’t supported natively in the browser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      